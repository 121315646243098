import CSidebar from 'components/side-bar/Index'
import CTopDesk from 'components/top-desk/Index'
import CMobileAppbar from 'components/mobile-app-bar/Index'
import CCircleStats from 'components/circle-stats/Index'
import CDaysBefore from 'components/days-before/Index'
import CGoBack from 'components/go-back/Index'
import CPhoneVerify from 'components/phone-verify/Index'
import CCodeInput from 'components/code-input/Index'
import CUserEdit from 'components/user-edit/Index'
import CModalContacts from 'components/modal-contacts/Index'
import CNewApplication from 'components/new-application/Index'
import CPasswordReset from 'components/reset-password/Index'
import CModalMessage from 'components/modal-message/Index'
import CEmpty from 'components/empty/Index'
import CFrame from 'components/frame/Index'
import CVerifyCode from 'components/verify-code/Index'
import CPin from 'components/pin/Index'
import CChangePin from 'components/change-pin/Index'
import CSelect from 'components/c-select/Index'
import CardSelect from 'components/card-select/Index'
import T from 'components/t/Index'
import CMyCalendar from 'components/my-calendar/Index'
import CSheet from 'components/sheet/Index.vue'
import CSelectCompany from 'components/select-company/Index.vue'
import CCountdownTimer from 'components/countdown-timer/Index.vue'
// import CModalSuccess from 'components/modal-success/ModalSuccess.vue'
import CModalNotification from 'components/modal-notification/ModalNotification'
import CModalBlock from 'components/modal-block/ModalBlock'
import CModalCache from 'components/modal-cache/ModalCache'
import CPagination from './c-pagination/CPagination.vue'
import CModalCreateAcw from './modal-create-acw/ModalCreateAcw.vue'
import CCalendare from 'vue2-datepicker';
import CModalAcwStatus from './modal-acw-status/ModalAcwStatus.vue'

export default {
  CSelect,
  CardSelect,
  CSidebar,
  CTopDesk,
  CMobileAppbar,
  CCircleStats,
  CDaysBefore,
  CGoBack,
  CPhoneVerify,
  CCodeInput,
  CUserEdit,
  CModalContacts,
  CNewApplication,
  CPasswordReset,
  CModalMessage,
  CEmpty,
  CFrame,
  CVerifyCode,
  CPin,
  CChangePin,
  T,
  CMyCalendar,
  CSheet,
  CSelectCompany,
  CCountdownTimer,
  // CModalSuccess,
  CModalNotification,
  CModalBlock,
  CModalCache,
  CPagination,
  CModalCreateAcw,
  CCalendare,
  CModalAcwStatus,
}
