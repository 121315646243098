import { Capacitor } from "@capacitor/core";

export default () => ({
  api: {
    servers: {
      default: $env("server"),
    },
    then: (response) => {
      if (Capacitor.getPlatform() !== "web") {
        let min = 0;
        if (localStorage.getItem("lastActivity")) {
          min = Math.floor(
            (new Date().getTime() -
              new Date(localStorage.getItem("lastActivity")).getTime()) /
              60000
          );
        }
        if (min < 5) {
          localStorage.setItem("lastActivity", new Date().toString());
        } else {
          $app.router.router().push({ name: "login" });
        }
      }

      if (!(response.data instanceof Blob)) {
        if (!response.data.content) {
          response.data = {
            ...response.data,
            content: response.data.data || response.data,
          };
        }
        if (response.data.message) {
          $app.ui.notify(response.data.message, "success");
        }
      }

      return response;
    },
    catch: (error) => {
      if (error.response?.status === 409) {
        throw error.response?.status;
      }

      if (error.response?.status === 403) {
        $app.auth.logout();
      } else if (error.response?.status === 422) {
        const { message, details } = error.response?.data || {};

        if (message === 'ValidationException') {
          throw details;
        } else if ( details === null ) {
          throw message;
        } else {
          throw message;
        }

      } else {
        const message = error.response?.data.message || "Сервер недоступен";
        console.log(message);
        throw error;
      }
      throw error;
    },
  },
  router: {
    config: {
      base: $env("baseUrl"),
      mode: "history",
    },
  },
  store: {
    saveDepth: 2,
  },
  auth: {
    loginRoute: "login",
    authRoute: "mobile.index",
    apiAuth: (token) => {
      $app.api.config({
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    },
  },
  date: {
    formats: {
      date: "DD.MM.YYYY",
      time: "HH:mm",
      datetime: "DD.MM.YYYY HH:mm",
    },
    default: "datetime",
  },
  form: {
    input: (self, name, type, form) => {
      if (type === "select") {
        return {
          name,
          value: self.$form.get(name, form),
          input: (value) => self.$form.set(name, value, form),
          danger: self.$form.errors(name, form),
          text: self.$form.editable(form) === false,
        };
      }
      return {
        name,
        value: self.$form.get(name, form),
        input: (value) => self.$form.set(name, value, form),
        danger: self.$form.errors(name, form),
        text: self.$form.editable(form) === false,
      };
    },
    validations: {
      required(value) {
        return value?.length > 0;
      },
      biggerThanMin(value) {
        return value >= 1000;
      },
      email(value) {
        return value.indexOf("@") !== -1;
      },
      phone(value) {
        return value.indexOf("+") !== -1;
      },
    },
    messages: {
      required: "Поле обязательно для заполнения",
      biggerThanMin: "Минимальная сумма снятия 1000",
      email: "Поле должно быть email адресом",
      phone: "Поле должно быть корректным телефонным номером",
      between: (name, params, value) =>
        `Поле должно быть от ${params[0]} до ${params[1]}`,
    },
  },
  secure: {
    whiteList: { p: [], br: [] },
  },
  ui: {
    duration: {
      info: 3000,
      danger: 3000,
      success: 3000,
    },
  },
});
