const columns = [
  { name: "createdAt", title: "Дата создания" },
  { name: "status", title: "Статус" },
  { name: "count", title: "Создано / Всего" },
];

const status = {
  NEW: "Новый",
  PROCESSING: "Подготовка к генерации АВР",
  IN_PROGRESS: "В процессе генерации АВР",
  FAILED: "Провал при попытке генерации",
  CANCELED: "Нет начислений для создания АВР",
  COMPLETED: "Обработка запроса завершена"
}

export { columns, status };
