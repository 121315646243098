<!-- eslint-disable eol-last -->
<!-- eslint-disable vue/multiline-html-element-content-newline -->
<template>
  <div v-if="show" class="modal-wrap" @click="closeModal">

    <div class="modal__content" @click.stop>
      <n-loader :loading="$var('load')" />
      <div class="modal__header">
        <button class="modal__close-btn" @click="closeModal">
          <img :src="closeIcon" alt="close icon">
        </button>
      </div>

      <div class="eds-documents-table">
        <n-table class="my-table" :columns="columns" :data="statusList">
          <template #createdAt="{ item }">
            {{ $app.date.format(item.createdAt, 'date') }}
            {{ $app.date.format(item.createdAt, 'time') }}
          </template>

          <template #status="{ item }">
            {{ status[item.status] }}
          </template>

          <template #count="{ item }">
            {{ item.createdCount + '/' + item.totalCount }}
          </template>
        </n-table>
      </div>

      <div class="btn-wrap">
        <button class="refresh-btn" @click="getData">Обновить</button>
      </div>


    </div>

  </div>
</template>

<script>
import closeIcon from 'assets/add-card-icon.svg'
import { columns, status } from './constants';


export default {
  props: {
    show: {
      type: Boolean,
    },
    companyId: {
      type: Number,
      required: true,
      default: 0
    }
  },
  data() {
    return {
      closeIcon,
      columns,
      status,
      statusList: [],
    }
  },
  watch: {
    show(newValue) {
      if (newValue) {
        document.body.style.overflow = 'hidden';
        this.getData();
      } else {
        document.body.style.overflow = '';
      }
    },
  },
  methods: {
    closeModal() {
      this.$emit('close')
    },

    formatDate(date) {
      const day = date.getDate().toString().padStart(2, '0');
      const month = (date.getMonth() + 1).toString().padStart(2, '0');
      const year = date.getFullYear();
      return `${day}.${month}.${year}`;
    },

    getData() {
      this.$var('load', true)

      const now = new Date();
      const year = now.getFullYear();
      const month = now.getMonth();
      const firstDay = new Date(year, month, 1);
      const lastDay = new Date(year, month + 1, 0);

      const request = {
        from: this.formatDate(firstDay),
        to: this.formatDate(lastDay)
      }

      $api.document.getAcwStatus(this.companyId)
        .query(request)
        .then((response) => {
          this.statusList = response.data.content;
        })
        .catch((error) => {
          console.error(error);
          $app.ui.notify('Не удалось получить статусы, обновите страницу', 'danger')
        })
        .finally(() => this.$var('load', false))
    }
  },
}

</script>

<style scoped lang="scss" src="./modal-acw-status.scss"></style>
