<!-- eslint-disable vue/multiline-html-element-content-newline -->
<!-- eslint-disable vue/valid-template-root -->
<!-- eslint-disable eol-last -->
<template>
  <div v-if="show" class="modal-wrap" @click="closeModal">

    <div class="modal__content" @click.stop>
      <n-loader :loading="$var('load')" />
      <div class="modal__header">
        <button class="modal__close-btn" @click="closeModal">
          <img :src="closeIcon" alt="close icon">
        </button>
      </div>
      <div class="modal__body">
        <c-calendare 
          v-model="dateRange" 
          range 
          format="DD.MM.YYYY" 
          :disabled-date="disableOutsideRange" 
          :disabled="hasDate"
        ></c-calendare>

        <n-button class="modal__btn" :disabled="dateRange === null" @click="createAcw">Сформировать</n-button>
      </div>
    </div>
  </div>
</template>

<script>
import closeIcon from 'assets/add-card-icon.svg'
import 'vue2-datepicker/locale/ru';
import 'vue2-datepicker/index.css';

export default {
  props: {
    show: {
      type: Boolean,
    },
    companyId: {
      type: Number,
      required: true,
      default: 0
    }
  },
  data: () => ({
    closeIcon,
    dateRange: null,
    allowedDates: {},
  }),
  computed: {
    hasDate() {
      return Object.keys(this.allowedDates).length === 0
    }
  },
  watch: {
    show(newValue) {
      if (newValue) {
        document.body.style.overflow = 'hidden';
        this.getAvalabelDate();
      } else {
        document.body.style.overflow = '';
      }
    },
    companyId: {
      immediate: true,
      handler(newVal) {
        if (newVal > 0) {
          this.getAvalabelDate();
        }
      }
    }
  },
  created() {
  },
  mounted() {
  },
  methods: {
    closeModal() {
      this.$emit('close')
      this.dateRange = null;
      this.allowedDates = {};
    },
    disableOutsideRange(date) {
      const [ startYear, startMonth, startDay ] = this.allowedDates.startDate.split('-').map(Number);
      const [ endYear, endMonth, endDay ] = this.allowedDates.endDate.split('-').map(Number);
      const startDate = new Date(startYear, startMonth - 1, startDay);
      const endDate = new Date(endYear, endMonth - 1, endDay);
      const currentDate = this.normalizeDate(date);
      return currentDate < startDate || currentDate > endDate;
    },
    normalizeDate(date) {
      return new Date(date.getFullYear(), date.getMonth(), date.getDate());
    },
    formatDate(date) {
      const year = date.getFullYear();
      const month = (date.getMonth() + 1).toString().padStart(2, '0');
      const day = date.getDate().toString().padStart(2, '0');
      return `${year}-${month}-${day}`;
    },

    getAvalabelDate() {
      this.$var('load', true)
      $api.document.getAvalabelRange(this.companyId)
        .then((response) => {
          this.allowedDates = response.data.content;          
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => this.$var('load', false))
    },
    createAcw() {
      this.$var('load', true)

      if (this.dateRange && this.dateRange.length === 2) {
        const payload = {
          startDate: this.formatDate(this.dateRange[0]),
          endDate: this.formatDate(this.dateRange[1]),
          companyId: this.companyId
        };

        $api.document.createAcw(payload)
          .then((response) => {
            if (response.status === 201) {
              this.closeModal();
              $app.ui.notify('Вы запустили процесс формирования АВР', 'success')
            } 
          })
          .catch((error) => {
            $app.ui.notify('Не удалось создать АВР, обновите страницу', 'danger')
          })
          .finally(() => this.$var('load', false))
      }

    },
  }
}
</script>

<style scoped lang="scss" src="./modal-create-acw.scss"></style>
<style lang="scss" src="./datepicker.scss"></style>
